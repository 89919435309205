const BASE_URL = process.env.REACT_APP_BASE_URL;

export const loginUrl = `${BASE_URL}/auth/login`;

export const getOrderSummaryUrl = `${BASE_URL}/order/get-order-summary`;
export const downloadOrderSummaryUrl = `${BASE_URL}/order/order-summary-download`;

export const getRedumptionOrderSummaryUrl = `${BASE_URL}/redumption-order-summary`;
export const downloadRedumptionOrderSummaryUrl = `${BASE_URL}/redumption-order-summary/export-excel`;
export const downloadRedumptionOrderSummaryBatchUrl = `${BASE_URL}/redumption-order-summary/batch-excel`;

export const getSchemeMasterUrl = `${BASE_URL}/scheme-master`;
export const getSchemeDetailsByISINUrl = `${BASE_URL}/scheme-master`;

export const setPermissionsUrl = `${BASE_URL}/auth/permissions`;
export const getPermissionsUrl = `${BASE_URL}/auth/get-permissions`;
export const updatePermissionUrl = id => `${BASE_URL}/auth/permission/${id}`;
export const deletePermissionUrl = id => `${BASE_URL}/auth/permission/${id}`;

export const setRoleUrl = `${BASE_URL}/auth/role`;
export const getRolesUrl = `${BASE_URL}/auth/roles`;
export const updateRolesUrl = id =>
  `${BASE_URL}/auth/update-role-permissions/${id}`;
export const deleteRoleUrl = id => `${BASE_URL}/auth/role/${id}`;

export const bankCourseMaster = `${BASE_URL}/bank-codes-master`;
export const updateBankCourseMaster = id =>
  `${BASE_URL}/bank-codes-master/${id}`;
export const deleteBankCourseMaster = id =>
  `${BASE_URL}/bank-codes-master/${id}`;
