import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { MDBDataTable } from "mdbreact";
import {
  setPermission,
  getPermissions,
  updatePermission,
  deletePermission,
} from "store/roles-permissions/permissions/permissions";
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { showSuccessAlert, showErrorAlert } from "utils/utils";

const Permissions = props => {
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [selectedPermissionId, setSelectedPermissionId] = useState("");
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const breadcrumbItems = [
    { title: "Galaxy", link: "#" },
    { title: "Permissions", link: "#" },
  ];
  const [formValues, setFormValues] = useState({
    name: "",
  });
  useEffect(() => {
    document.title = "Permissions | Galaxy";
    props.setBreadcrumbItems("Permissions", breadcrumbItems);

    fetchPermissionsData();
  }, []);

  const fetchPermissionsData = async () => {
    setLoading(true);
    try {
      const response = await getPermissions();
      const data = response.data;
      if (data && data.isSuccess) {
        setPermissions(data.payload.permissions || []);
        setLoading(false);
      } else {
        setError("Failed to fetch permissions.");
        setLoading(false);
      }
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const handleAddClick = () => {
    setFormValues({ name: "" });
    setIsEdit(false);
    toggleModal();
  };

  const handleEditClick = permission => {
    setFormValues({ name: permission.name });
    setSelectedPermissionId(permission.id);
    setIsEdit(true);
    toggleModal();
  };

  const handleDeleteClick = permissionId => {
    setSelectedPermissionId(permissionId);
    toggleDeleteModal();
  };

  const handleValidSubmit = async (event, values) => {
    try {
      if (isEdit) {
        const resp = await updatePermission(selectedPermissionId, { name: values.name });
        if (resp.data.isSuccess) {
          showSuccessAlert(resp.data.message);
        }
  
        if (!resp.data.isSuccess) {
          showErrorAlert(resp.data.message)
        }
      } else {
        const resp = await setPermission({ name: values.name });
        if (resp.data.isSuccess) {
          showSuccessAlert(resp.data.message);
        }
  
        if (!resp.data.isSuccess) {
          showErrorAlert(resp.data.message)
        }
      }
      fetchPermissionsData();
      toggleModal();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      const resp = await deletePermission(selectedPermissionId);
      if (resp.data.isSuccess) {
        showSuccessAlert(resp.data.message);
      }

      if (!resp.data.isSuccess) {
        showErrorAlert(resp.data.message)
      }
      fetchPermissionsData();
      toggleDeleteModal();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Define table data for MDBDataTable
  const data = {
    columns: [
      {
        label: "Permission",
        field: "name",
        sort: "disabled",
        width: 6,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "disabled",
        width: 6,
      },
    ],
    rows: permissions.map(permission => ({
      name: permission.name,
      actions: (
        <>
          <Button
            className="border clear-button"
            onClick={() => handleEditClick(permission)}
          >
            Edit
          </Button>{" "}
          <Button
            className="delete-btn border-danger bg-transparent text-danger"
            onClick={() => handleDeleteClick(permission.id)}
          >
            Delete
          </Button>
        </>
      ),
    })),
  };

  const toggleModal = () => setModal(!modal);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);

  return (
    <React.Fragment>
      <Row className="mb-4">
        <Col className="col-12">
          <Card>
            <CardBody>
              <Row>
                <Col xs="12">
                  <div className="align-items-center d-flex justify-content-between mb-3">
                    <h4 className="text-body">Permissions</h4>

                    <div className="d-flex justify-content-end">
                      <Button color="primary" onClick={handleAddClick}>
                        Add Permission
                      </Button>
                    </div>
                  </div>
                  {loading ? (
                    <p>Loading...</p>
                  ) : error ? (
                    <p>Error: {error}</p>
                  ) : (
                    <MDBDataTable
                      responsive
                      bordered
                      data={data}
                      searching={true}
                      noBottomColumns
                    />
                  )}
                </Col>
              </Row>

              <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>
                  {isEdit ? "Edit Permission" : "Add Permission"}
                </ModalHeader>
                <AvForm onValidSubmit={handleValidSubmit}>
                  <ModalBody>
                    <Label for="name">Permission Name*</Label>
                    <AvField
                      name="name"
                      placeholder="Enter Permission"
                      type="text"
                      errorMessage="Enter Permission"
                      validate={{ required: { value: true } }}
                      value={formValues.name}
                      onChange={e =>
                        setFormValues({ ...formValues, name: e.target.value })
                      }
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" type="submit">
                      {isEdit ? "Update" : "Add"}
                    </Button>
                    <Button color="secondary" onClick={toggleModal}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </AvForm>
              </Modal>

              <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
                <ModalHeader toggle={toggleDeleteModal}>
                  Delete Permission
                </ModalHeader>
                <ModalBody>
                  Are you sure you want to delete the permission with id "
                  {selectedPermissionId}"?
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" onClick={handleDeleteConfirm}>
                    Delete
                  </Button>
                  <Button color="secondary" onClick={toggleDeleteModal}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

// export default Permissions;
export default connect(null, { setBreadcrumbItems })(Permissions);
