import axios from "axios";
import { loginUrl } from "routes/apiRoute";

export const loginUserApi = async (email, password) => {
  try {
    const response = await axios.post(loginUrl, { email, password });
    return response.data;
  } catch (error) {
    console.error(
      "Login API error:",
      error.response ? error.response.data : error.message,
    );
    throw error;
  }
};
