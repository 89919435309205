
import axios from "axios";
import {
  getRolesUrl,
  setRoleUrl,
  updateRolesUrl,
  deleteRoleUrl,
  getPermissionsUrl,
} from "routes/apiRoute";

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("authUser")}`,
  },
});

export const fetchRoles = async () => {
  try {
    const response = await axiosInstance.get(getRolesUrl);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const createRole = async roleData => {
  try {
    const response = await axiosInstance.post(setRoleUrl, roleData);
    return response.data;
  } catch (error) {
    return error.response.data
  }
};

export const updateRolePermissions = async (roleId, roleData) => {
  try {
    const response = await axiosInstance.put(updateRolesUrl(roleId), roleData);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const deleteRole = async roleId => {
  try {
    const response = await axiosInstance.delete(deleteRoleUrl(roleId));
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const fetchPermissions = async () => {
  try {
    const response = await axiosInstance.get(getPermissionsUrl);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
