import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import "./style.css";
const CustomPagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = page => {
    if (page < 1 || page > totalPages) return;
    onPageChange(page);
  };

  const renderPageButtons = () => {
    let buttons = [];
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <Button
          key={i}
          color={i === currentPage ? "primary" : "transparent"}
          className="border custom-button"
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Button>,
      );
    }

    return buttons;
  };
  const buttonStyle = disabled => ({
    backgroundColor: disabled ? "grey" : "",
  });
  return (
    <div className="d-flex  gap-1">
      <Button
        color=""
        className="border custom-button fs-5"
        onClick={() => handlePageChange(1)}
        disabled={currentPage === 1}
        style={buttonStyle(currentPage === 1)}
      >
        <i className="mdi mdi-chevron-double-left"></i>
      </Button>{" "}
      <Button
        color=""
        className="border custom-button fs-5"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        style={buttonStyle(currentPage === 1)}
      >
        <i className="mdi mdi-chevron-left"></i>
      </Button>
      {renderPageButtons()}
      <Button
        color=""
        className="border custom-button fs-5"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        style={buttonStyle(currentPage === totalPages)}
      >
        <i className="mdi mdi-chevron-right"></i>
      </Button>
      <Button
        color=""
        className="border custom-button fs-5"
        onClick={() => handlePageChange(totalPages)}
        disabled={currentPage === totalPages}
        style={buttonStyle(currentPage === totalPages)}
      >
        <i className="mdi mdi-chevron-double-right"></i>
      </Button>
    </div>
  );
};

CustomPagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default CustomPagination;
