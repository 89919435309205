import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import calendarSaga from "./calendar/saga";
import chatSaga from "./chat/saga";
import tasksSaga from "./tasks/saga";
// import orderSummarySaga from "./orderSummary/sagas";
// import permissionsSaga from "./roles-permissions/permissions/saga";
// import RolesSaga from "./roles-permissions/roles/saga";

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    LayoutSaga(),
    // permissionsSaga(),
    // RolesSaga(),

    fork(calendarSaga),
    // fork(orderSummarySaga),
    fork(chatSaga),
    fork(tasksSaga),
    fork(tasksSaga),
  ]);
}
