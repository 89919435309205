// api.js
import axios from "axios";
import {
  setPermissionsUrl,
  getPermissionsUrl,
  updatePermissionUrl,
  deletePermissionUrl,
} from "routes/apiRoute";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem("authUser");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

export const setPermission = async payload => {
  try {
    const response = await axiosInstance.post(setPermissionsUrl, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPermissions = async () => {
  try {
    const response = await axiosInstance.get(getPermissionsUrl);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updatePermission = async (id, payload) => {
  try {
    const url = updatePermissionUrl(id);
    const response = await axiosInstance.put(url, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deletePermission = async id => {
  try {
    const url = deletePermissionUrl(id);
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};
