// src/constants/selectData.js

export const selectData = [
  {
    heading: "Purchase Type",
    options: [
      { label: "ALL", value: "" },
      { label: "FRESH", value: "FRESH" },
      { label: "ADDITIONAL", value: "ADDITIONAL" },
      { label: "AUTO SIP", value: "AUTO SIP" },
    ],
  },
  {
    heading: "First Order Flag",
    options: [
      { label: "BOTH", value: "" },
      { label: "YES", value: "Y" },
      { label: "NO", value: "N" },
    ],
  },
  {
    heading: "Order Status",
    options: [
      { label: "ALL", value: "" },
      { label: "SUCCESS", value: "SUCCESS" },
      { label: "PENDING", value: "PENDING" },
      { label: "INPROGRESS", value: "INPROGRESS" },
    ],
  },
  {
    heading: "Payment Status",
    options: [
      { label: "ALL", value: "" },
      { label: "SUCCESS", value: "SUCCESS" },
      { label: "PENDING", value: "PENDING" },
      { label: "FAILED", value: "FAILED" },
    ],
  },
  {
    heading: "Order Type",
    options: [
      { label: "ALL", value: "" },
      { label: "SIP", value: "SIP" },
      { label: "ONETIME", value: "ONETIME" },
      { label: "CONDITIONAL ORDER", value: "CONDITIONAL ORDER" },
    ],
  },
];

export const selectRedumptionOrderData = [
  {
    heading: "Status",
    options: [
      { label: "ALL", value: "" },
      { label: "PENDING", value: "Pending" },
      { label: "IN PROGRESS", value: "In Progress" },
      { label: "SUCCESS", value: "Success" },
      { label: "FAILED", value: "Failed" },
      { label: "CANCEL", value: "Cancel" },
      { label: "REJECT", value: "Reject" },
    ],
  },
];

export const getFieldName = heading => {
  switch (heading) {
    case "Purchase Type":
      return "purchaseType";
    case "First Order Flag":
      return "firstOrderFlag";
    case "Order Status":
      return "orderStatus";
    case "Payment Status":
      return "paymentStatus";
    case "Order Type":
      return "orderType";
    default:
      return heading.toLowerCase().replace(/\s+/g, "");
  }
};
