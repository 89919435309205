import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Button,
  InputGroup,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import CustomSelect from "./CustomSelect";
import CustomPagination from "./CustomPagination";
import "./style.css";
import {
  downloadOrderSummary,
} from "store/orderSummary/orderSummary";
import { selectData, getFieldName } from "./Constant";
import * as XLSX from "xlsx";
import { Workbook } from "exceljs";
import { getSchemeDetailsByISIN, getSchemeMasterDetails, syncSchemeByISIN } from "store/schemeMaster/schemeMaster";
import { AvForm, AvGroup, AvInput } from "availity-reactstrap-validation";
import { showErrorAlert, showSuccessAlert } from "utils/utils";

const allDefaultSelectOption = {
  label: "ALL",
  value: "",
};

const bothDefaultSelectOption = {
  label: "BOTH",
  value: "",
};

const defaultFilterOptions = {
  purchaseType: allDefaultSelectOption,
  firstOrderFlag: bothDefaultSelectOption,
  orderStatus: allDefaultSelectOption,
  paymentStatus: allDefaultSelectOption,
  orderType: allDefaultSelectOption,
  fromDate: null,
  toDate: null,
};

const SchemeMaster = props => {
  const dispatch = useDispatch();
  const [filterDetails, setFilterDetails] = useState(defaultFilterOptions);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState({ columns: [], rows: [] });
  const [orderSummaryPages, setOrderSummaryPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [dateError, setDateError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [schemeDetails, setSchemeDetails] = useState([]);

  const handleFromDateChange = dates => {
    if (dates.length > 0) {
      const formattedDate = formatDate(dates[0]);
      setFromDate(formattedDate);
      setDateError("");
    }
  };

  const handleView = async item => {
    const ISINO = item.ISINO;
    const schemeDetailsResp = await getSchemeDetailsByISIN(ISINO);
    if (schemeDetailsResp.length) {
      setSchemeDetails(schemeDetailsResp[0])
    }
    setModalOpen(true);
  }

  const handleSync = async item => {
    const ISINO = item.ISINO;
    if (ISINO && ISINO !== '') {
      const syncSchemeResp = await syncSchemeByISIN(ISINO)
      if (syncSchemeResp.isSuccess) {
        showSuccessAlert("Scheme Sync Completed!");
        fetchSchemeMasterDetails();
      }
    } else {
      showErrorAlert("ISIN is required!")
    }
  }

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    // if (!modalOpen) {
    //   // Reset fields when modal is closed
    //   setMode("");
    //   setBankName("");
    //   setBankCode("");
    //   setMicr("");
    //   setEditMode(false);
    //   setCurrentId(null);
    // }
  };

  const handleToDateChange = dates => {
    if (dates.length > 0) {
      const formattedDate = formatDate(dates[0]);
      if (fromDate && formattedDate < fromDate) {
        setDateError("To Date should be greater than or equal to From Date");
      } else {
        setToDate(formattedDate);
        setDateError("");
      }
    }
  };

  useEffect(() => {
    setFilterDetails(prevState => ({
      ...prevState,
      fromDate,
      toDate,
    }));
  }, [fromDate, toDate]);

  const formatDate = date => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  useEffect(() => {
    document.title = "Scheme Master | Galaxy";
  }, []);

  useEffect(() => {
    fetchSchemeMasterDetails();
  }, [currentPage, filterDetails]);

  const fetchSchemeMasterDetails = async () => {
    setLoading(true);
    try {
      const response = await getSchemeMasterDetails(filterDetails, currentPage);

      if (response && response.data && response.data.payload) {
        const schemMaster = response.data.payload.schemMasterMasterList || [];
        const formattedData = formatDataForTable(schemMaster);
        setTableData(formattedData);

        const totalPages = response.data.payload.totalPages || 1;
        setOrderSummaryPages(totalPages);
      }
    } catch (error) {
      console.error("Error fetching scheme master:", error);
    } finally {
      setLoading(false);
    }
  };

  const breadcrumbItems = [
    { title: "Galaxy", link: "#" },
    { title: "Scheme Master", link: "#" },
  ];

  useEffect(() => {
    props.onSetBreadCrumbs("Scheme Master", breadcrumbItems);
  }, [props]);

  const formatDataForTable = orderSummary => {
    if (!orderSummary || orderSummary.length === 0)
      return { columns: [], rows: [] };

    const columns = [
      { label: "Scheme Name", field: "sch_name", sort: "disabled" },
      { label: "Min Investment", field: "minimumInverstment", sort: "disabled" },
      { label: "Since Inception", field: "SinceInception", sort: "disabled" },
      { label: "Fund Category", field: "FundCategory", sort: "disabled" },
      { label: "Sub Category", field: "subcategory", sort: "disabled" },
      { label: "Launch Date", field: "LaunchDate", sort: "disabled" },
      { label: "Expense Ratio", field: "ExpenseRatio", sort: "disabled" },
      { label: "Fund type", field: "FundType", sort: "disabled" },
      { label: "Exit Load", field: "ExitLoad", sort: "disabled" },
      {
        label: "Actions",
        field: "actions",
        sort: "disabled",
        width: 150,
      },
    ];

    const rows = orderSummary.map(item => ({
      sch_name: item.sch_name,
      minimumInverstment: item.minimumInverstment,
      SinceInception: item.SinceInception,
      FundCategory: item.FundCategory,
      subcategory: item.subcategory,
      LaunchDate: item.LaunchDate,
      ExpenseRatio: item.ExpenseRatio,
      FundType: item.FundType,
      ExitLoad: item.ExitLoad,
      actions: (
        <>
          <Button
            className="border clear-button"
            onClick={() => handleView(item)}
          >
            <i className="mdi mdi-eye" />
            {" "} View
          </Button>{" "}
          <Button
            className="border clear-button"
            onClick={() => handleSync(item)}
          >
            <i className="mdi mdi-database-sync" />
            {" "}
            Sync
          </Button>
        </>
      ),
    }));

    return { columns, rows };
  };

  const handleSelectChange = (field, value) => {
    setFilterDetails(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchSchemeMasterDetails();
  };

  const handleClearSearch = () => {
    setFilterDetails(defaultFilterOptions);
    setFromDate("");
    setToDate("");
    setCurrentPage(1);
    fetchSchemeMasterDetails();
  };

  const handleExportToExcel = async () => {
    try {
      const response = await downloadOrderSummary(filterDetails);
      const data = response.data && response.data.payload.OrderSummary;

      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Data");

      const headerRow = worksheet.addRow(Object.keys(data[0]));

      headerRow.eachCell(cell => {
        cell.font = {
          bold: true,
        };
      });

      data.forEach(row => {
        worksheet.addRow(Object.values(row));
      });

      const fileName = "OrderSummary.xlsx";
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      workbook.xlsx
        .writeBuffer()
        .then(buffer => {
          const blob = new Blob([buffer], { type: fileType });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        })
        .catch(err => {
          console.error("Error writing Excel file:", err);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePageChange = page => {
    setCurrentPage(page);
    fetchSchemeMasterDetails();
  };

  const exportExcel = (tableData, filename = "table.xlsx") => {
    const ws = XLSX.utils.json_to_sheet(tableData.rows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, filename);
  };

  return (
    <React.Fragment>
      <Row className="mb-4">
        <Col className="col-12">
          <Card>
            <CardBody>
              <Modal size="xl" isOpen={modalOpen} toggle={toggleModal}>
                <ModalHeader>
                  {"Scheme Details"}
                </ModalHeader>
                <AvForm>
                  <ModalBody>
                    <Row>
                      <Col md={4}>
                        <AvGroup>
                          <Row>
                            <Col md={4}>
                              <Label>MF SCHCODE</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.MF_SCHCODE}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Label>SCHEME NAME</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.SCH_NAME}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Label>DATE</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.DATE}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Label>oneweek</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.oneweek}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Label>onemonth</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.onemonth}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Label>threemonth</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.threemonth}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Label>sixmonth</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.sixmonth}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Label>oneyear</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.oneyear}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Label>threeyear</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.threeyear}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Label>fiveyear</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.fiveyear}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Label>inception</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.inception}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Label>ISIN</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.ISin}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Label>SCHCLCODE</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.SCHCLCODE}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Label>SCHCLASS</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.SCHCLASS}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Label>MF_SCHCODE1</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.MF_SCHCODE1}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Label>WEEKMAX</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.WEEKMAX}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Label>WMAXCODE</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.WMAXCODE}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Label>WEEKMIN</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.WEEKMIN}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Label>WMINCODE</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.WMINCODE}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Label>WEEKAVG</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.WEEKAVG}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Label>ONEMMAX</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.ONEMMAX}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Label>ONEMMAXCODE</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.ONEMMAXCODE}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Label>ONEMMIN</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.ONEMMIN}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Label>ONEMMINCODE</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.ONEMMINCODE}</span>
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>


                      <Col md={4}>
                        <AvGroup>
                          <Row>
                            <Col md={4}>
                              <Label>ONEMAVG</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.ONEMAVG}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>THREEMMAX</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.THREEMMAX}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>THREEMMAXCODE</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.THREEMMAXCODE}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>THREEMMIN</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.THREEMMIN}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>THREEMMINCODE</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.THREEMMINCODE}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>THREEMAVG</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.THREEMAVG}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>SIXMMAX</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.SIXMMAX}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>SIXMMAXCODE</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.SIXMMAXCODE}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>SIXMIN</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.SIXMIN}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>SixMMINCODE</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.SixMMINCODE}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>SIXMAVG</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.SIXMAVG}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>ONEYRMAX</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.ONEYRMAX}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>ONEYRMIN</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.ONEYRMIN}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>ONEYRMINCODE</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.ONEYRMINCODE}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>ONEYRAVG</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.ONEYRAVG}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>THREEYRMAX</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.THREEYRMAX}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>THREEYRMAXCODE</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.THREEYRMAXCODE}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>THREEYRMIN</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.THREEYRMIN}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>THREEYRMINCODE</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.THREEYRMINCODE}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>THREEYRAVG</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.THREEYRAVG}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>FIVEYRMAX</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.FIVEYRMAX}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>FIVEYRMAXCODE</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.FIVEYRMAXCODE}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>FIVEYRMIN</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.FIVEYRMIN}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>FIVEYRMINCODE</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.FIVEYRMINCODE}</span>
                            </Col>
                          </Row>
                        </AvGroup>
                      </Col>
                      <Col md={4}>
                        <AvGroup>
                          <Row>
                            <Col md={4}>
                              <Label>FIVEYRAVG</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.FIVEYRAVG}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>wmaxname</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.wmaxname}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>wminname</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.wminname}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>onemmaxname</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.onemmaxname}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>onemminname</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.onemminname}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>threemmaxname</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.threemmaxname}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>threemminname</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.threemminname}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>Sixmmaxname</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.Sixmmaxname}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>sixminname</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.sixminname}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>oneyrmaxname</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.oneyrmaxname}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>oneyrminname</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.oneyrminname}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>threeyrmaxname</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.threeyrmaxname}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>threeyrminname</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.threeyrminname}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>Fiveyrmaxname</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.Fiveyrmaxname}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>fiveyrminname</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.fiveyrminname}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>incmaxname</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.incmaxname}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>incminname</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.incminname}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>INCMAX</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.INCMAX}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>INCMAXCODE</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.INCMAXCODE}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>INCMIN</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.INCMIN}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>INCMINCODE</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.INCMINCODE}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>INCAVG</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.INCAVG}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>ISINO</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.ISINO}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <Label>ExpenseRatio</Label>
                            </Col>
                            <Col md={8}>
                              <span>: {schemeDetails.ExpenseRatio}</span>
                            </Col>
                          </Row>

                        </AvGroup>
                      </Col>
                    </Row>
                  </ModalBody>
                </AvForm>
              </Modal>

              <h4 className="mb-3 text-body">Scheme Master</h4>
              <div className="row">
                <FormGroup className="mb-4 col-2">
                  <Label>From Date</Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="MM/DD/YYYY"
                      options={{
                        altInput: true,
                        altFormat: "m/d/Y",
                        dateFormat: "m/d/Y",
                      }}
                      value={fromDate}
                      onChange={handleFromDateChange}
                    />
                  </InputGroup>
                  <div className="text-danger">{dateError}</div>
                </FormGroup>
                <FormGroup className="mb-4 col-2">
                  <Label>To Date</Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="MM/DD/YYYY"
                      options={{
                        altInput: true,
                        altFormat: "m/d/Y",
                        dateFormat: "m/d/Y",
                      }}
                      value={toDate}
                      onChange={handleToDateChange}
                    />
                  </InputGroup>
                </FormGroup>
                {selectData.map((select, index) => {
                  const field = getFieldName(select.heading);
                  return (
                    <div key={index} className="mb-3 col-2">
                      <CustomSelect
                        heading={select.heading}
                        value={filterDetails[field]}
                        onChange={value => handleSelectChange(field, value)}
                        options={select.options}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="row">
                <div className="col-2 mb-4">
                  <Button
                    type="button"
                    color=""
                    className="fs-5 waves-effect border clear-button w-100 waves-light"
                    onClick={handleClearSearch}
                  >
                    <i className="mdi mdi-close-thick"></i> Clear Search
                  </Button>
                </div>
              </div>
              {loading ? (
                <div className="text-center mt-4">
                  {" "}
                  <Spinner
                    color="primary"
                    style={{ width: "3rem", height: "3rem" }}
                  />
                </div>
              ) : tableData.rows.length > 0 ? (
                <>
                  <MDBDataTable
                    responsive
                    data={tableData}
                    paging={false}
                    displayEntries={false}
                    noBottomColumns
                  />
                  <div className="d-flex justify-content-between mt-3">
                    <CustomPagination
                      currentPage={currentPage}
                      totalPages={orderSummaryPages}
                      onPageChange={handlePageChange}
                    />
                    {/* <div className="col-2">
                      <Button
                        type="button"
                        color="primary"
                        className="fs-5 waves-effect w-100 waves-light"
                        onClick={handleExportToExcel}
                      >
                        <i className="mdi mdi-microsoft-excel"></i> Export to
                        Excel
                      </Button>
                    </div> */}
                  </div>
                </>
              ) : (
                <div className="text-center mt-4">No data to show</div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

SchemeMaster.propTypes = {
  onSetBreadCrumbs: PropTypes.func,
};

const mapDispatchToProps = dispatch => {
  return {
    onSetBreadCrumbs: (title, items) =>
      dispatch(setBreadcrumbItems(title, items)),
  };
};

export default connect(null, mapDispatchToProps)(SchemeMaster);
