import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Button,
  InputGroup,
  Spinner,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import CustomSelect from "./CustomSelect";
import CustomPagination from "./CustomPagination";
import "./style.css";
import {
  downloadOrderSummary,
  getOrderSummary,
} from "store/orderSummary/orderSummary";
import { selectData, getFieldName } from "./Constant";
import * as XLSX from "xlsx";
import axios from "axios";
import { Workbook } from "exceljs";

const allDefaultSelectOption = {
  label: "ALL",
  value: "",
};

const bothDefaultSelectOption = {
  label: "BOTH",
  value: "",
};

const defaultFilterOptions = {
  purchaseType: allDefaultSelectOption,
  firstOrderFlag: bothDefaultSelectOption,
  orderStatus: allDefaultSelectOption,
  paymentStatus: allDefaultSelectOption,
  orderType: allDefaultSelectOption,
  fromDate: null,
  toDate: null,
};

const OrderSummary = props => {
  const dispatch = useDispatch();
  const [filterDetails, setFilterDetails] = useState(defaultFilterOptions);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState({ columns: [], rows: [] });
  const [orderSummaryPages, setOrderSummaryPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [dateError, setDateError] = useState(null);

  const handleFromDateChange = dates => {
    if (dates.length > 0) {
      const formattedDate = formatDate(dates[0]);
      setFromDate(formattedDate);
      setDateError("");
    }
  };

  const handleToDateChange = dates => {
    if (dates.length > 0) {
      const formattedDate = formatDate(dates[0]);
      if (fromDate && formattedDate < fromDate) {
        setDateError("To Date should be greater than or equal to From Date");
      } else {
        setToDate(formattedDate);
        setDateError("");
      }
    }
  };

  useEffect(() => {
    setFilterDetails(prevState => ({
      ...prevState,
      fromDate,
      toDate,
    }));
  }, [fromDate, toDate]);

  const formatDate = date => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  useEffect(() => {
    document.title = "Order Summary | Galaxy";
  }, []);

  useEffect(() => {
    fetchOrderSummary();
  }, [currentPage, filterDetails]);

  const fetchOrderSummary = async () => {
    setLoading(true);
    try {
      const response = await getOrderSummary(filterDetails, currentPage);
      if (response && response.data && response.data.payload) {
        const orderSummary = response.data.payload.OrderSummary || [];
        const formattedData = formatDataForTable(orderSummary);
        setTableData(formattedData);

        const totalPages = response.data.payload.totalPages || 1;
        setOrderSummaryPages(totalPages);
      }
    } catch (error) {
      console.error("Error fetching order summary:", error);
    } finally {
      setLoading(false);
    }
  };

  const breadcrumbItems = [
    { title: "Galaxy", link: "#" },
    { title: "Order Summary", link: "#" },
  ];

  useEffect(() => {
    props.onSetBreadCrumbs("Order Summary", breadcrumbItems);
  }, [props]);

  const formatDataForTable = orderSummary => {
    if (!orderSummary || orderSummary.length === 0)
      return { columns: [], rows: [] };

    const columns = Object.keys(orderSummary[0]).map(key => ({
      label: key,
      field: key,
      sort: "asc",
      width: 150,
    }));

    const rows = orderSummary.map((item, index) => ({
      ...item,
    }));

    return { columns, rows };
  };

  const handleSelectChange = (field, value) => {
    setFilterDetails(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchOrderSummary();
  };

  const handleClearSearch = () => {
    setFilterDetails(defaultFilterOptions);
    setFromDate("");
    setToDate("");
    setCurrentPage(1);
    fetchOrderSummary();
  };

  const handleExportToExcel = async () => {
    try {
      const response = await downloadOrderSummary(filterDetails);
      const data = response.data && response.data.payload.OrderSummary;

      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Data");

      const headerRow = worksheet.addRow(Object.keys(data[0]));

      headerRow.eachCell(cell => {
        cell.font = {
          bold: true,
        };
      });

      data.forEach(row => {
        worksheet.addRow(Object.values(row));
      });

      const fileName = "OrderSummary.xlsx";
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      workbook.xlsx
        .writeBuffer()
        .then(buffer => {
          const blob = new Blob([buffer], { type: fileType });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        })
        .catch(err => {
          console.error("Error writing Excel file:", err);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePageChange = page => {
    setCurrentPage(page);
    fetchOrderSummary();
  };

  const exportExcel = (tableData, filename = "table.xlsx") => {
    const ws = XLSX.utils.json_to_sheet(tableData.rows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, filename);
  };

  return (
    <React.Fragment>
      <Row className="mb-4">
        <Col className="col-12">
          <Card>
            <CardBody>
              <h4 className="mb-3 text-body">Order Summary</h4>
              <div className="row">
                <FormGroup className="mb-4 col-2">
                  <Label>From Date</Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="MM/DD/YYYY"
                      options={{
                        altInput: true,
                        altFormat: "m/d/Y",
                        dateFormat: "m/d/Y",
                      }}
                      value={fromDate}
                      onChange={handleFromDateChange}
                    />
                  </InputGroup>
                  <div className="text-danger">{dateError}</div>
                </FormGroup>
                <FormGroup className="mb-4 col-2">
                  <Label>To Date</Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="MM/DD/YYYY"
                      options={{
                        altInput: true,
                        altFormat: "m/d/Y",
                        dateFormat: "m/d/Y",
                      }}
                      value={toDate}
                      onChange={handleToDateChange}
                    />
                  </InputGroup>
                </FormGroup>
                {selectData.map((select, index) => {
                  const field = getFieldName(select.heading);
                  return (
                    <div key={index} className="mb-3 col-2">
                      <CustomSelect
                        heading={select.heading}
                        value={filterDetails[field]}
                        onChange={value => handleSelectChange(field, value)}
                        options={select.options}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="row">
                {/* <div className="col-2 mb-4 d-none">
                  <Button
                    type="button"
                    color="primary"
                    className="fs-5 border waves-effect custom-button w-100 waves-light"
                    onClick={handleSearch}
                  >
                    <i className="mdi mdi-file-search-outline"></i> Search
                  </Button>
                </div> */}
                <div className="col-2 mb-4">
                  <Button
                    type="button"
                    color=""
                    className="fs-5 waves-effect border clear-button w-100 waves-light"
                    onClick={handleClearSearch}
                  >
                    <i className="mdi mdi-close-thick"></i> Clear Search
                  </Button>
                </div>
              </div>
              {loading ? (
                <div className="text-center mt-4">
                  {" "}
                  <Spinner
                    color="primary"
                    style={{ width: "3rem", height: "3rem" }}
                  />
                </div>
              ) : tableData.rows.length > 0 ? (
                <>
                  <MDBDataTable
                    responsive
                    data={tableData}
                    paging={false}
                    displayEntries={false}
                    noBottomColumns
                  />
                  <div className="d-flex justify-content-between mt-3">
                    <CustomPagination
                      currentPage={currentPage}
                      totalPages={orderSummaryPages}
                      onPageChange={handlePageChange}
                    />
                    <div className="col-2">
                      <Button
                        type="button"
                        color="primary"
                        className="fs-5 waves-effect w-100 waves-light"
                        onClick={handleExportToExcel}
                      >
                        <i className="mdi mdi-microsoft-excel"></i> Export to
                        Excel
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="text-center mt-4">No data to show</div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

OrderSummary.propTypes = {
  onSetBreadCrumbs: PropTypes.func,
};

const mapDispatchToProps = dispatch => {
  return {
    onSetBreadCrumbs: (title, items) =>
      dispatch(setBreadcrumbItems(title, items)),
  };
};

export default connect(null, mapDispatchToProps)(OrderSummary);
