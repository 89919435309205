import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { MDBDataTable } from "mdbreact";
import { setBreadcrumbItems } from "../../store/actions";

import {
  fetchRoles,
  createRole,
  updateRolePermissions,
  deleteRole,
  fetchPermissions,
} from "store/roles-permissions/roles/role";
import { showSuccessAlert, showErrorAlert } from "utils/utils";

const EmailInbox = props => {
  document.title = "Roles | Galaxy";
  const breadcrumbItems = [
    { title: "Galaxy", link: "#" },
    { title: "Roles", link: "#" },
  ];
  const [name, setName] = useState("");
  const [permissionIds, setPermissionIds] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editRole, setEditRole] = useState({
    id: "",
    name: "",
    permissions: [],
  });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [roleToDelete, setRoleToDelete] = useState(null);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [rolePermissions, setRolePermissions] = useState([]);

  useEffect(() => {
    props.setBreadcrumbItems("Roles", breadcrumbItems);

    fetchRolesData();
    fetchPermissionsData();
  }, []);

  const fetchRolesData = async () => {
    setLoading(true);
    try {
      const response = await fetchRoles();
      setRoles(response.data.payload || []);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch roles.");
      setLoading(false);
    }
  };

  const fetchPermissionsData = async () => {
    try {
      const response = await fetchPermissions();
      setRolePermissions(response.data.payload.permissions || []);
    } catch (error) {
      setError("Failed to fetch permissions.");
    }
  };

  const handleAddClick = () => {
    setName("");
    setPermissionIds([]);
    setIsEditMode(false);
    setModalOpen(true);
  };

  const handleEditClick = role => {
    setEditRole(role);
    setName(role.name);
    setPermissionIds(
      role.permissions ? role.permissions.map(perm => perm.id) : [],
    );
    setIsEditMode(true);
    setModalOpen(true);
  };

  const handleDeleteClick = roleId => {
    setRoleToDelete(roleId);
    setDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const resp = await deleteRole(roleToDelete);
      fetchRolesData();
      fetchPermissionsData();
      setDeleteModalOpen(false);
      if (resp.data.isSuccess) {
        showSuccessAlert('Role deleted successfully!');
      }
    } catch (error) {
      showErrorAlert('Failed to delete role!');
      setError("Failed to delete role.");
    }
  };

  const handleCheckboxChange = (e, permissionId) => {
    if (e.target.checked) {
      setPermissionIds([...permissionIds, permissionId]);
    } else {
      setPermissionIds(permissionIds.filter(id => id !== permissionId));
    }
  };

  const handleValidSubmit = async (event, values) => {
    const roleData = { name: values.role, permissionIds };
    try {
      if (isEditMode) {
        const resp = await updateRolePermissions(editRole.id, roleData);
        if (resp.data.isSuccess) {
          showSuccessAlert('Role and Permissions updated successfully!');
        }

        if (!resp.data.isSuccess) {
          showErrorAlert('Failed to update Role and Permissions!');
        }
      } else {
        const resp = await createRole(roleData);
        if (resp.data.isSuccess) {
          showSuccessAlert(resp.data.message);
        }

        if (!resp.data.isSuccess) {
          showErrorAlert(resp.data.message)
        }
      }
      fetchRolesData();
      fetchPermissionsData();
      setModalOpen(false);
    } catch (error) {
      setError("Failed to save role.");
    }
  };

  const columns = [
    { label: "Role Name", field: "name", sort: "disabled" },
    { label: "Permissions", field: "permissions", sort: "disabled" },
    {
      label: "Action",
      field: "action",
      sort: "disabled",
      width: 150,
    },
  ];

  const rows = roles.map(role => ({
    name: role.name,
    permissions: role.permissions
      ? role.permissions.map(perm => perm.name).join(", ")
      : "",
    action: (
      <>
        <Button
          className="border clear-button"
          onClick={() => handleEditClick(role)}
        >
          Edit
        </Button>{" "}
        <Button
          className="delete-btn border-danger bg-transparent text-danger"
          onClick={() => handleDeleteClick(role.id)}
        >
          Delete
        </Button>
      </>
    ),
  }));

  const data = {
    columns: columns,
    rows: rows,
  };

  return (
    <React.Fragment>
      <Row className="mb-4">
        <Col className="col-12">
          <Card>
            <CardBody>
              {" "}
              <Row>
                <div className="align-items-center d-flex justify-content-between mb-3">
                  <h4 className=" text-body">Roles</h4>
                  <div className="d-flex justify-content-end">
                    <Button color="primary" onClick={handleAddClick}>
                      Add Role
                    </Button>
                  </div>
                </div>
              </Row>
              <Row>
                <Col xs="12">
                  <MDBDataTable
                    bordered
                    data={data}
                    noBottomColumns
                    noRecordsFoundLabel="No roles found"
                  />
                </Col>
              </Row>
              <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
                <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
                  {isEditMode ? "Edit Role" : "Add Role"}
                </ModalHeader>
                <AvForm onValidSubmit={handleValidSubmit}>
                  <ModalBody>
                    <Label for="role">Role Name *</Label>
                    <AvField
                      name="role"
                      placeholder="Role Name"
                      type="text"
                      errorMessage="Enter Role"
                      validate={{ required: { value: true } }}
                      value={name}
                      onChange={e => setName(e.target.value)}
                      className="mb-3"
                    />
                    <div>
                      {rolePermissions.map(permission => (
                        <FormGroup check key={permission.id}>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={permissionIds.includes(permission.id)}
                              onChange={e =>
                                handleCheckboxChange(e, permission.id)
                              }
                            />
                            {permission.name}
                          </Label>
                        </FormGroup>
                      ))}
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button type="submit" color="success">
                      {isEditMode ? "Update Role" : "Create Role"}
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() => setModalOpen(!modalOpen)}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </AvForm>
              </Modal>
              <Modal
                isOpen={deleteModalOpen}
                toggle={() => setDeleteModalOpen(!deleteModalOpen)}
              >
                <ModalHeader
                  toggle={() => setDeleteModalOpen(!deleteModalOpen)}
                >
                  Confirm Delete
                </ModalHeader>
                <ModalBody>
                  Are you sure you want to delete this role?
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" onClick={confirmDelete}>
                    Delete
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => setDeleteModalOpen(!deleteModalOpen)}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

// export default EmailInbox;
export default connect(null, { setBreadcrumbItems })(EmailInbox);
