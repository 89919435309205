import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Form,
  Alert,
  Input,
  FormFeedback,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { loginUserApi } from "store/auth/login/login";
import jainamLogo from "../../assets/images/jainamLogo.svg"
const Login = props => {
  const navigate = useNavigate();
  document.title = "Login | Galaxy";

  const [error, setError] = useState(null);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "admin@jainam.com" || "",
      password: "admin123" || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async values => {
      try {
        const response = await loginUserApi(values.email, values.password);
        const permissions = response?.data?.payload?.permissions;
        const accessToken = response?.data?.payload?.token;
        localStorage.setItem("authUser", accessToken);
        if (Array.isArray(permissions)) {
          localStorage.setItem("permissions", JSON.stringify(permissions));
        }
        navigate("/dashboard");
      } catch (error) {
        console.error("Login failed:", error);
        setError(error.response ? error.response.data.message : "Login failed");
      }
    },
  });

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <Link to="/" className="d-block auth-logo">
                      <img
                        src={jainamLogo}
                        alt=""
                        height="30"
                        className="auth-logo-dark"
                      />
                    </Link>
                  </h3>

                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">
                      Welcome Back!
                    </h4>
                    <p className="text-muted text-center">
                      Sign in to continue to Galaxy.
                    </p>
                    <Form
                      className="form-horizontal mt-4"
                      onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}
                      <div className="mb-3">
                        <Label htmlFor="username">Username</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="userpassword">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3 mt-4">
                        <div className="col-6">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              Remember me
                            </label>
                          </div>
                        </div>
                        <div className="col-6 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>
                      </Row>
                      {/* <Row className="form-group mb-0">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock"></i> Forgot your password?
                        </Link>
                      </Row> */}
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-5 text-center">
                {/* <p>
                  Don't have an account?{" "}
                  <Link to="/register" className="text-primary">
                    Signup Now
                  </Link>
                </p> */}
                © {new Date().getFullYear()} Galaxy{" "}
                <span className="d-none d-sm-inline-block">
                  - Jainam Broking Limited
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;

Login.propTypes = {
  router: PropTypes.object,
};
