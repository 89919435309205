import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Button,
  InputGroup,
  Spinner,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import CustomSelect from "./CustomSelect";
import CustomPagination from "./CustomPagination";
import "./style.css";
import { getFieldName, selectRedumptionOrderData } from "./Constant";
import { Workbook } from "exceljs";
import { downloadRedumptionOrderSummaryToExcel, downloadRedumptionOrderSummaryToExcelBatch, getRedumptionOrderSummary } from "store/redumptionOrderSummary/redumptionOrderSummary";

const allDefaultSelectOption = {
  label: "ALL",
  value: "",
};

const defaultFilterOptions = {
  fromDate: null,
  toDate: null,
  fromExeDate: null,
  toExeDate: null,
  status: allDefaultSelectOption,
};

const RedumptionOrderSummary = props => {
  const [filterDetails, setFilterDetails] = useState(defaultFilterOptions);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromExeDate, setFromExeDate] = useState("");
  const [toExeDate, setToExeDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState({ columns: [], rows: [] });
  const [orderSummaryPages, setOrderSummaryPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [dateError, setDateError] = useState(null);
  const [exeDateError, setExeDateError] = useState(null);

  const handleFromDateChange = dates => {
    if (dates.length > 0) {
      const formattedDate = formatDate(dates[0]);
      setFromDate(formattedDate);
      setDateError("");
    }
  };

  const handleToDateChange = dates => {
    if (dates.length > 0) {
      const formattedDate = formatDate(dates[0]);
      if (fromDate && formattedDate < fromDate) {
        setDateError("To Order Date should be greater than or equal to From Order Date");
      } else {
        setToDate(formattedDate);
        setDateError("");
      }
    }
  };

  const handleFromExeDateChange = dates => {
    if (dates.length > 0) {
      const formattedDate = formatDate(dates[0]);
      setFromExeDate(formattedDate);
      setExeDateError("");
    }
  };

  const handleToExeDateChange = dates => {
    if (dates.length > 0) {
      const formattedDate = formatDate(dates[0]);
      if (fromExeDate && formattedDate < fromExeDate) {
        setExeDateError("To Execute Date should be greater than or equal to From Execute Date");
      } else {
        setToExeDate(formattedDate);
        setExeDateError("");
      }
    }
  };

  useEffect(() => {
    setFilterDetails(prevState => ({
      ...prevState,
      fromDate,
      toDate,
      fromExeDate,
      toExeDate,
    }));
  }, [fromDate, toDate, fromExeDate, toExeDate]);

  const formatDate = date => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    document.title = "Redumption Ord Summary | Galaxy";
  }, []);

  useEffect(() => {
    fetchOrderSummary();
  }, [currentPage, filterDetails]);

  const fetchOrderSummary = async () => {
    setLoading(true);
    try {
      const response = await getRedumptionOrderSummary(filterDetails, currentPage);

      if (response && response.data && response.data.payload) {
        const orderSummary = response.data.payload.RedumptionOrderSummary
        || [];

        const formattedData = formatDataForTable(orderSummary);
        setTableData(formattedData);

        const totalPages = response.data.payload.totalPages || 1;
        setOrderSummaryPages(totalPages);
      }
    } catch (error) {
      console.error("Error fetching order summary:", error);
    } finally {
      setLoading(false);
    }
  };

  const breadcrumbItems = [
    { title: "Galaxy", link: "#" },
    { title: "Redumption Ord Summary", link: "#" },
  ];

  useEffect(() => {
    props.onSetBreadCrumbs("Redumption Ord Summary", breadcrumbItems);
  }, [props]);

  const formatDataForTable = orderSummary => {
    if (!orderSummary || orderSummary.length === 0)
      return { columns: [], rows: [] };

    const columns = Object.keys(orderSummary[0]).map(key => ({
      label: key,
      field: key,
      sort: "asc",
      width: 150,
    }));

    const rows = orderSummary.map((item, index) => ({
      ...item,
    }));

    return { columns, rows };
  };

  const handleSelectChange = (field, value) => {
    setFilterDetails(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleClearSearch = () => {
    setFilterDetails(defaultFilterOptions);
    setFromDate("");
    setToDate("");
    setFromExeDate("");
    setToExeDate("");
    setCurrentPage(1);
    fetchOrderSummary();
  };

  const handleExportToExcel = async () => {
    try {
      const response = await downloadRedumptionOrderSummaryToExcel(filterDetails);
      const data = response.data && response.data.payload.RedumptionOrderSummary;
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Data");
      const headerRow = worksheet.addRow(Object.keys(data[0]));

      headerRow.eachCell(cell => {
        cell.font = {
          bold: true,
        };
      });

      data.forEach(row => {
        worksheet.addRow(Object.values(row));
      });

      const fileName = "RedumptionOrderSummary.xlsx";
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      workbook.xlsx
        .writeBuffer()
        .then(buffer => {
          const blob = new Blob([buffer], { type: fileType });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        })
        .catch(err => {
          console.error("Error writing Excel file:", err);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleExportToBatchExcel = async () => {
    try {
      const response = await downloadRedumptionOrderSummaryToExcelBatch(filterDetails);
      const data = response.data && response.data.payload.RedumptionOrderSummary;
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Data");
      worksheet.getCell('A1').value = `SETT.NO-${data[0]["SettlementNo"]}`;
      worksheet.getCell('C1').value = 'SERIAL NO';
      worksheet.getCell('E1').value = `DATE :${new Date().toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}`;
      worksheet.getRow(2).height = 15;

      const headerRow = worksheet.addRow(Object.keys(data[0]));
      headerRow.eachCell(cell => {
        cell.font = {
          bold: true,
        };
      });

      data.forEach(row => {
        worksheet.addRow(Object.values(row));
      });

      const fileName = "BatchRedumptionOrderSummary.xlsx";
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      workbook.xlsx
        .writeBuffer()
        .then(buffer => {
          const blob = new Blob([buffer], { type: fileType });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        })
        .catch(err => {
          console.error("Error writing Excel file:", err);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePageChange = page => {
    setCurrentPage(page);
    fetchOrderSummary();
  };

  return (
    <React.Fragment>
      <Row className="mb-4">
        <Col className="col-12">
          <Card>
            <CardBody>
              <h4 className="mb-3 text-body">Redumption Order Summary</h4>
              <div className="row">
              {selectRedumptionOrderData.map((select, index) => {
                  const field = getFieldName(select.heading);
                  return (
                    <div key={index} className="mb-3 col-2">
                      <CustomSelect
                        heading={select.heading}
                        value={filterDetails[field]}
                        onChange={value => handleSelectChange(field, value)}
                        options={select.options}
                      />
                    </div>
                  );
                })}
                <FormGroup className="mb-4 col-2">
                  <Label>From Order Date</Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="DD/MM/YYYY"
                      options={{
                        altInput: true,
                        altFormat: "d/m/Y",
                        dateFormat: "d/m/Y",
                      }}
                      value={fromDate}
                      onChange={handleFromDateChange}
                    />
                  </InputGroup>
                  <div className="text-danger">{dateError}</div>
                </FormGroup>
                <FormGroup className="mb-4 col-2">
                  <Label>To Order Date</Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="DD/MM/YYYY"
                      options={{
                        altInput: true,
                        altFormat: "d/m/Y",
                        dateFormat: "d/m/Y",
                      }}
                      value={toDate}
                      onChange={handleToDateChange}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup className="mb-4 col-2">
                  <Label>From Execute Date</Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="DD/MM/YYYY"
                      options={{
                        altInput: true,
                        altFormat: "d/m/Y",
                        dateFormat: "d/m/Y",
                      }}
                      value={fromExeDate}
                      onChange={handleFromExeDateChange}
                    />
                  </InputGroup>
                  <div className="text-danger">{exeDateError}</div>
                </FormGroup>
                <FormGroup className="mb-4 col-2">
                  <Label>To Execute Date</Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="DD/MM/YYYY"
                      options={{
                        altInput: true,
                        altFormat: "d/m/Y",
                        dateFormat: "d/m/Y",
                      }}
                      value={toExeDate}
                      onChange={handleToExeDateChange}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup className="mb-4 col-2">
                  <div style={{ height: '28px' }} /> 
                  <InputGroup>
                    <Button
                      type="button"
                      color=""
                      className="fs-5 waves-effect border clear-button w-100 waves-light"
                      onClick={handleClearSearch}
                    >
                      <i className="mdi mdi-close-thick"></i> Clear Search
                    </Button>
                  </InputGroup>
                </FormGroup>
              </div>
              {loading ? (
                <div className="text-center mt-4">
                  {" "}
                  <Spinner
                    color="primary"
                    style={{ width: "3rem", height: "3rem" }}
                  />
                </div>
              ) : tableData.rows.length > 0 ? (
                <>
                  <MDBDataTable
                    responsive
                    data={tableData}
                    paging={false}
                    displayEntries={false}
                    noBottomColumns
                  />
                  <div className="d-flex justify-content-between mt-3">
                    <CustomPagination
                      currentPage={currentPage}
                      totalPages={orderSummaryPages}
                      onPageChange={handlePageChange}
                    />
                    <div className="d-flex justify-content-end">
                      <div className="mx-2">
                        <Button
                          type="button"
                          color="primary"
                          className="fs-5 waves-effect w-100 waves-light"
                          onClick={handleExportToBatchExcel}
                        >
                          <i className="mdi mdi-microsoft-excel"></i> Batch Excel
                        </Button>
                      </div>
                      <div className="mx-2">
                        <Button
                          type="button"
                          color="primary"
                          className="fs-5 waves-effect w-100 waves-light"
                          onClick={handleExportToExcel}
                        >
                          <i className="mdi mdi-microsoft-excel"></i> Export to Excel
                        </Button>
                      </div>
                    </div>
                    </div>
                </>
              ) : (
                <div className="text-center mt-4">No data to show</div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

RedumptionOrderSummary.propTypes = {
  onSetBreadCrumbs: PropTypes.func,
};

const mapDispatchToProps = dispatch => {
  return {
    onSetBreadCrumbs: (title, items) =>
      dispatch(setBreadcrumbItems(title, items)),
  };
};

export default connect(null, mapDispatchToProps)(RedumptionOrderSummary);
