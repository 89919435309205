import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { setBreadcrumbItems } from "../../store/actions";
import {
  fetchBankCodes,
  createBankCode,
  deleteBankCode,
  updateBankCode,
} from "store/Bank/BankCourseMaster";
import CustomPagination from "pages/OrderSummary/CustomPagination";
import {
  Row,
  Col,
  Card,
  Input,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import debounce from "lodash.debounce";
import { AvForm, AvField } from "availity-reactstrap-validation";
import "./style.css";
import { showSuccessAlert, showErrorAlert } from "utils/utils";

const BankCodeMaster = props => {
const [data, setData] = useState({ columns: [], rows: [] });
const [currentPage, setCurrentPage] = useState(1);
const [totalPages, setTotalPages] = useState(1);
const [searchQuery, setSearchQuery] = useState("");
const [mode, setMode] = useState("");
const [bankName, setBankName] = useState("");
const [bankCode, setBankCode] = useState("");
const [micr, setMicr] = useState("");
const [modalOpen, setModalOpen] = useState(false);
const [editMode, setEditMode] = useState(false);
const [currentId, setCurrentId] = useState(null);
const [deleteModalOpen, setDeleteModalOpen] = useState(false);
const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    document.title = "Galaxy | Bank Code Master";
  }, []);

  const breadcrumbItems = [
    { title: "Galaxy", link: "#" },
    { title: "Bank Code Master", link: "#" },
  ];
  useEffect(() => {
    props.setBreadcrumbItems("Bank Code Master", breadcrumbItems);
  }, [props]);

  useEffect(() => {
    getData(currentPage, searchQuery);
  }, [currentPage, searchQuery]);

  const getData = async (page, query) => {
    try {
      const response = await fetchBankCodes(page, 10, query);
      const bankCodesMasterList = response.data.payload.bankCodesMasterList;
      setTotalPages(response.data.payload.totalPages);

      const columns = [
        { label: "Mode", field: "Mode", sort: "disabled" },
        { label: "Bank Name", field: "BankName", sort: "disabled" },
        { label: "Bank Code", field: "BankCode", sort: "disabled" },
        { label: "MICR", field: "MICR", sort: "disabled" },
        {
          label: "Actions",
          field: "actions",
          sort: "disabled",
          width: 150,
        },
      ];

      const rows = bankCodesMasterList.map(item => ({
        Id: item.Id,
        Mode: item.Mode,
        BankName: item.BankName,
        BankCode: item.BankCode,
        MICR: item.MICR,
        actions: (
          <>
            <Button
              className="border clear-button"
              onClick={() => handleEdit(item)}
            >
              Edit
            </Button>{" "}
            <Button
              className="delete-btn border-danger bg-transparent text-danger"
              onClick={() => handleDeleteConfirmation(item.Id)}
            >
              Delete
            </Button>
          </>
        ),
      }));

      setData({ columns, rows });
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const debouncedSearch = useCallback(
    debounce(query => {
      setCurrentPage(1);
      setSearchQuery(query);
    }, 300),
    [],
  );

  const handleSearchChange = event => {
    debouncedSearch(event.target.value);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    if (!modalOpen) {
      // Reset fields when modal is closed
      setMode("");
      setBankName("");
      setBankCode("");
      setMicr("");
      setEditMode(false);
      setCurrentId(null);
    }
  };

  const handleEdit = item => {
    setMode(item.Mode);
    setBankName(item.BankName);
    setBankCode(item.BankCode);
    setMicr(item.MICR);
    setCurrentId(item.Id);
    setEditMode(true);
    setModalOpen(true);
  };

  const handleDeleteConfirmation = id => {
    setDeleteId(id);
    setDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    try {
      const resp = await deleteBankCode(deleteId);
      setCurrentPage(1);
      getData(1, searchQuery);
      setDeleteModalOpen(false);

      if (resp.data.isSuccess) {
        showSuccessAlert(resp.data.message);
      }

      if (!resp.data.isSuccess) {
        showErrorAlert(resp.data.message)
      }
    } catch (error) {
      console.error("Error deleting bank code", error);
    }
  };

  const handleSubmit = async (event, errors) => {
    if (errors.length === 0) {
      try {
        const resp = await createBankCode(mode, bankName, bankCode, micr);
        if (resp.data.isSuccess) {
          showSuccessAlert(resp.data.message);
        }

        if (!resp.data.isSuccess) {
          showErrorAlert(resp.data.message);
        }
        toggleModal();
        getData(currentPage, searchQuery);
      } catch (error) {
        console.error("Error creating bank code", error);
      }
    }
  };

  const handleEditSubmit = async (event, errors) => {
    if (errors.length === 0) {
      try {
        const resp = await updateBankCode(currentId, mode, bankName, bankCode, micr);
        toggleModal();
        getData(currentPage, searchQuery);
        if (resp.data.isSuccess) {
          showSuccessAlert('Bank Code Detail updated successfully!');
        }

        if (!resp.data.isSuccess) {
          showErrorAlert(resp.data.message);
        }
      } catch (error) {
        console.error("Error updating bank code", error);
      }
    }
  };

  return (
    <React.Fragment>
      <Row className="mb-4">
        <Col className="col-12">
          <Card>
            <CardBody>
              <div className="align-items-center d-flex justify-content-between mb-3">
                <h4 className="text-body">Bank Code Master</h4>
                <Button color="primary" onClick={toggleModal}>
                  Add Bank Code
                </Button>
              </div>
              <Modal isOpen={modalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>
                  {editMode ? "Edit Bank Code" : "Add Bank Code"}
                </ModalHeader>
                <AvForm onSubmit={editMode ? handleEditSubmit : handleSubmit}>
                  <ModalBody>
                    <AvField
                      name="mode"
                      label="Mode *"
                      type="text"
                      value={mode}
                      onChange={e => setMode(e.target.value)}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter a mode",
                        },
                      }}
                    />
                    <AvField
                      name="bankName"
                      label="Bank Name *"
                      type="text"
                      value={bankName}
                      onChange={e => setBankName(e.target.value)}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter a bank name",
                        },
                      }}
                    />
                    <AvField
                      name="bankCode"
                      label="Bank Code *"
                      type="text"
                      value={bankCode}
                      onChange={e => setBankCode(e.target.value)}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter a bank code",
                        },
                      }}
                    />
                    <AvField
                      name="micr"
                      label="MICR *"
                      type="number"
                      value={micr}
                      onChange={e => {
                        const input = e.target.value;
                        if (/^\d{0,3}$/.test(input)) {
                          setMicr(input);
                        }
                      }}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter a MICR",
                        },
                        pattern: {
                          value: /^\d{3}$/,
                          errorMessage: "MICR must be exactly 3 digits",
                        },
                      }}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button type="submit" color="primary">
                      {editMode ? "Update Bank Code" : "Add Bank Code"}
                    </Button>{" "}
                    <Button color="secondary" onClick={toggleModal}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </AvForm>
              </Modal>
              <Input
                type="text"
                name="search"
                placeholder="Search"
                className="mb-4"
                onChange={handleSearchChange}
              />
              <MDBDataTable
                searching={false}
                bordered
                paging={false}
                noBottomColumns
                data={data}
              />
              <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal
        isOpen={deleteModalOpen}
        toggle={() => setDeleteModalOpen(!deleteModalOpen)}
      >
        <ModalHeader toggle={() => setDeleteModalOpen(!deleteModalOpen)}>
          Delete Confirmation
        </ModalHeader>
        <ModalBody>Are you sure you want to delete this bank code?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDelete}>
            Delete
          </Button>
          <Button
            color="secondary"
            onClick={() => setDeleteModalOpen(!deleteModalOpen)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default connect(null, { setBreadcrumbItems })(BankCodeMaster);
