import axios from "axios";
import { downloadOrderSummaryUrl, getSchemeDetailsByISINUrl, getSchemeMasterUrl } from "routes/apiRoute";

export const getSchemeMasterDetails = async (filterDetails, currentPage) => {
  try {
    const authToken = localStorage.getItem("authUser");
    const requestBody = {
      inPageNo: currentPage || "1",
      inPageSize: "10",
      stSearch: "",
      stFromDate: filterDetails.fromDate || "",
      stToDate: filterDetails.toDate || "",
      stPurchaseType: filterDetails.purchaseType?.value || "",
      stFirstOrderFlag: filterDetails.firstOrderFlag?.value || "",
      stOrderStatus: filterDetails.orderStatus?.value || "",
      stPaymentStatus: filterDetails.paymentStatus?.value || "",
      stOrderType: filterDetails.orderType?.value || "",
    };

    const response = await axios.get(`${getSchemeMasterUrl}?limit=10&page=${currentPage}&search=${requestBody.stSearch}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const downloadOrderSummary = async (filterDetails) => {
  try {
    const authToken = localStorage.getItem("authUser");
    const requestBody = {
      stSearch: "",
      stFromDate: filterDetails.fromDate || "",
      stToDate: filterDetails.toDate || "",
      stPurchaseType: filterDetails.purchaseType?.value || "",
      stFirstOrderFlag: filterDetails.firstOrderFlag?.value || "",
      stOrderStatus: filterDetails.orderStatus?.value || "",
      stPaymentStatus: filterDetails.paymentStatus?.value || "",
      stOrderType: filterDetails.orderType?.value || "",
    };

    const response = await axios.post(downloadOrderSummaryUrl, requestBody, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSchemeDetailsByISIN = async (ISINO) => {
  try {
    const authToken = localStorage.getItem("authUser");

    const response = await axios.get(`${getSchemeDetailsByISINUrl}/get-scheme-list/${ISINO}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data.data.payload.data.schemelist.scheme;
  } catch (error) {
    throw error;
  }
};

export const syncSchemeByISIN = async (ISINO) => {
  try {
    const authToken = localStorage.getItem("authUser");

    const response = await axios.get(`${getSchemeDetailsByISINUrl}/sync-scheme-list/${ISINO}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data.data
  } catch (error) {
    throw error;
  }
};
