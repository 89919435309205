import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { FormGroup, Label } from "reactstrap";
import "./style.css"
const CustomSelect = ({
  value,
  onChange,
  options,
  heading,
  placeholder,
  ...rest
}) => {
  return (
    <FormGroup className="mb-4">
      {heading && <Label>{heading}</Label>}
      <Select
        value={value}
        // defaultValue={value}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        classNamePrefix="select2-selection"
        {...rest}
      />
    </FormGroup>
  );
};

CustomSelect.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  heading: PropTypes.string,
  placeholder: PropTypes.string,
};

export default CustomSelect;
