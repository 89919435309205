import axios from "axios";
import {
  bankCourseMaster,
  deleteBankCourseMaster,
  updateBankCourseMaster,
} from "routes/apiRoute";

const authToken = localStorage.getItem("authUser");

export const fetchBankCodes = async (page, limit, search) => {
  try {
    const response = await axios.get(
      `${bankCourseMaster}?page=${page}&limit=${limit}&search=${search}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createBankCode = async (mode, bankName, bankCode, micr) => {
  try {
    const response = await axios.post(
      `${bankCourseMaster}`,
      {
        Mode: mode,
        BankName: bankName,
        BankCode: bankCode,
        MICR: micr,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateBankCode = async (id, mode, bankName, bankCode, micr) => {
  try {
    const response = await axios.put(
      updateBankCourseMaster(id),
      {
        Mode: mode,
        BankName: bankName,
        BankCode: bankCode,
        MICR: micr,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteBankCode = async id => {
  try {
    const response = await axios.delete(deleteBankCourseMaster(id), {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
