import axios from "axios";
import { downloadRedumptionOrderSummaryBatchUrl, downloadRedumptionOrderSummaryUrl, getRedumptionOrderSummaryUrl } from "routes/apiRoute";

export const getRedumptionOrderSummary = async (filterDetails, currentPage) => {
  try {
    const authToken = localStorage.getItem("authUser");
    const requestBody = {
      inPageNo: currentPage || "1",
      inPageSize: "10",
      FromDate: filterDetails.fromDate || "",
      ToDate: filterDetails.toDate || "",
      ExeFromDate: filterDetails.fromExeDate || "",
      ExeToDate: filterDetails.toExeDate || "",
      stStatus: filterDetails.status?.value || "",
    };

    const response = await axios.post(getRedumptionOrderSummaryUrl, requestBody, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const downloadRedumptionOrderSummaryToExcel = async (filterDetails) => {
  try {
    const authToken = localStorage.getItem("authUser");
    const requestBody = {
      FromDate: filterDetails.fromDate || "",
      ToDate: filterDetails.toDate || "",
      ExeFromDate: filterDetails.fromExeDate || "",
      ExeToDate: filterDetails.toExeDate || "",
      stStatus: filterDetails.status?.value || "",
    };

    const response = await axios.post(downloadRedumptionOrderSummaryUrl, requestBody, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const downloadRedumptionOrderSummaryToExcelBatch = async (filterDetails) => {
  try {
    const authToken = localStorage.getItem("authUser");
    const requestBody = {
      FromDate: filterDetails.fromDate || "",
      ToDate: filterDetails.toDate || "",
      ExeFromDate: filterDetails.fromExeDate || "",
      ExeToDate: filterDetails.toExeDate || "",
      stStatus: filterDetails.status?.value || "",
    };

    const response = await axios.post(downloadRedumptionOrderSummaryBatchUrl, requestBody, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
